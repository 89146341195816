import styled from 'styled-components';

export const Head = styled.div`
  margin: 7px 5px;
  line-height: 200%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeadTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeadSub = styled.div`
  flex-shrink: 0;
  margin-top: -4px;
`;
